import { MilestoneProps } from '../../../../components/milestone';
import { ProgramIcons } from '../requirements';
import { AccountBenefits } from '../types';

interface BenefitInterface {
  key: keyof AccountBenefits;
  title: string;
}

export const ListOfBenefits: BenefitInterface[] = [
  {
    key: 'DedicatedVetCareSupportTeam',
    title: 'Dedicated VetCare Support Team'
  },
  {
    key: 'QuartelyBusinessReviews',
    title: 'Quarterly Business Reviews'
  },
  {
    key: 'PersonalizedECommerceMarketing',
    title: 'Personalized E-commerce Marketing Support'
  },
  {
    key: 'InventoryManagementEducation',
    title: 'Inventory Management Education'
  },
  {
    key: 'ExclusiveOSHAEducation',
    title: 'Exclusive OSHA education'
  },
  {
    key: 'OneOnOneTrainingAndOnboarding',
    title: '1:1 Training & Onboarding'
  },
  {
    key: 'HypercareTechnologySupport',
    title: 'Hypercare Technology Support'
  },
  {
    key: 'PriorityAccessToInnovationAndAllocationAndBackorderedProducts',
    title: 'Priority Access to Innovation and Allocation/Backordered Products'
  },
  {
    key: 'WhiteGloveService',
    title: 'White Glove Service'
  },
  {
    key: 'AfterHoursTechSupport',
    title: 'After-hours Technology Support'
  },
  {
    key: 'PracticePerformanceReporting',
    title: 'On-Demand Practice Performance Reporting'
  },
  {
    key: 'PracticeBenchmarkReporting',
    title: 'Practice Benchmark Reporting'
  },
  {
    key: 'WaiveShippingFees',
    title: 'Waive Shipping Fees'
  }
];

export const PSIVetCoreBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing'
      }
    ]
  }
];

export const PSIVetSelectBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: '1:1 Training & Onboarding'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description:
          'Priority Access to Innovation and Allocation/Backordered Products'
      }
    ]
  }
];

export const PSIVetPremierBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: '1:1 Training & Onboarding'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description:
          'Priority Access to Innovation and Allocation/Backordered Products'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'White Glove Service'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'After Hours Tech Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'On Demand Practice Performance Reporting'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Practice Benchmark Reporting'
      }
    ]
  }
];

export const VMGCoreBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Inventory Management Education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Exclusive OSHA Education'
      }
    ]
  }
];

export const VMGSelectBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Inventory Management Education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Exclusive OSHA Education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: '1:1 Training & Onboarding'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description:
          'Priority Access to Innovation and Allocation/Backordered Products'
      }
    ]
  }
];

export const VMGPremierBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Inventory Management Education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Exclusive OSHA Education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: '1:1 Training & Onboarding'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description:
          'Priority Access to Innovation and Allocation/Backordered Products'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'White Glove Service'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'After Hours Tech Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'On Demand Practice Performance Reporting'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Practice Benchmark Reporting'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Waive Shipping Fees'
      }
    ]
  }
];

export const IndependentCoreBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing'
      }
    ]
  }
];

export const IndependentSelectBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: '1:1 Training & Onboarding'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Hypercare Technology Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description:
          'Priority Access to Innovation and Allocation/Backordered Products'
      }
    ]
  }
];

export const IndependentPremierBenefits = [
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: '1:1 Training & Onboarding'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Hypercare Technology Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description:
          'Priority Access to Innovation and Allocation/Backordered Products'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'White Glove Service'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'On Demand Practice Performance Reporting'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Practice Benchmark Reporting'
      }
    ]
  }
];

export const PSIVetExclusiveBenefits14500: MilestoneProps[] = [
  {
    icon: ProgramIcons.Dollar,
    title: 'Rebate is $4,250 per DM',
    steps: [
      {
        completed: false,
        description: 'Total rebate is $8,500'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Inventory Management Education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Exclusive OSHA education'
      }
    ]
  }
];

export const PSIVetExclusiveBenefits30000: MilestoneProps[] = [
  {
    icon: ProgramIcons.Dollar,
    title: 'Rebate is $7,500 per DM',
    steps: [
      {
        completed: false,
        description: 'Total rebate is $15,000'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Inventory Management Education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Exclusive OSHA education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: '1:1 Training & Onboarding'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description:
          'Priority Access to Innovation and Allocation/Backordered Products'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'White Glove Service'
      }
    ]
  }
];

export const PSIVetExclusiveBenefits40500: MilestoneProps[] = [
  {
    icon: ProgramIcons.Dollar,
    title: 'Rebate is $10,250 per DM',
    steps: [
      {
        completed: false,
        description: 'Total rebate is $20,500'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Dedicated VetCare Support Team'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Quarterly Business Reviews'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Personalized E-commerce Marketing Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Inventory Management Education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Exclusive OSHA education'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: '1:1 Training & Onboarding'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description:
          'Priority Access to Innovation and Allocation/Backordered Products'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'White Glove Service'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'After-hours Technology Support'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'On-Demand Practice Performance Reporting'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Practice Benchmark Reporting'
      }
    ]
  },
  {
    steps: [
      {
        completed: true,
        description: 'Waive Shipping Fees'
      }
    ]
  }
];
