import { useState } from 'react';
import {
  SSelectDropdown,
  SelectDetailsButton,
  SingleSelectList
} from '@setvi/shared/components';
import { Box } from '@mui/material';
import { ContractType } from '@setvi/shared/services';

interface ContractDropdownProps {
  selectedContract: ContractType;
  onSelectContract: (contract: ContractType) => void;
}

export const ContractDropdown = ({
  selectedContract,
  onSelectContract
}: ContractDropdownProps) => {
  const [anchor, setAnchor] = useState(null);

  return (
    <SSelectDropdown
      anchor={
        <SelectDetailsButton
          width={250}
          title={selectedContract}
          onClick={e => setAnchor(e?.currentTarget)}
          open={Boolean(anchor)}
        />
      }
      anchorElement={anchor}
      onClose={() => setAnchor(null)}
      content={
        <Box width={285}>
          <SingleSelectList
            list={[ContractType.FULL_CONTRACT, ContractType.ACCOUNT]}
            displayValueGetter={el => el}
            keyGetter={el => el}
            isSelected={el => el === selectedContract}
            onSelect={contract => {
              onSelectContract(contract);
              setAnchor(null);
            }}
          />
        </Box>
      }
    />
  );
};
