import { createContext, useContext } from 'react';
import { ContractType, VetsuiteOverview } from '@setvi/shared/services';
import { Account } from './types';

export type VetsuiteDigitalShowroomContextType = {
  account: Account;
  allAccounts: Account[];
  client: any;
  currentUser: any;
  realmDbName: string;
  customerId: string;
  data: VetsuiteOverview;
  contract: VetsuiteOverview;
  token: string;
  contractType: ContractType;
  setContractType: (contractType: ContractType) => void;
};

export const VetsuiteDigitalShowroomContext =
  createContext<VetsuiteDigitalShowroomContextType>({
    account: null,
    allAccounts: [],
    client: null,
    currentUser: null,
    realmDbName: '',
    customerId: '',
    data: null,
    contract: null,
    token: '',
    contractType: ContractType.FULL_CONTRACT,
    setContractType: () => {}
  });

export const useVetsuiteDigitalShowroomContext = () => {
  const context = useContext(VetsuiteDigitalShowroomContext);

  if (context === undefined)
    throw new Error(
      `useVetsuiteDigitalShowroomContext must be used within a DigitalShowroomContext.Provider`
    );

  return context;
};
