import { Box, Divider } from '@mui/material';
import { SText, STooltip } from '@setvi/shared/components';
import { Account, ContractType } from '@setvi/shared/services';
import { ContractDropdown } from './contract-dropdown';

interface ContentheaderProps {
  title: string;
  subtitle: JSX.Element;
  displayChangeClinic?: boolean;
  selectedContract: ContractType;
  showContractDropdown: boolean;
  accounts: Account[];
  onChangeClinic: () => void;
  onSelectContract: (contract: ContractType) => void;
}

export const ContentHeader = ({
  title,
  subtitle,
  displayChangeClinic,
  selectedContract,
  showContractDropdown,
  accounts,
  onChangeClinic,
  onSelectContract
}: ContentheaderProps) => (
  <Box m={4} display="flex">
    <Box flexShrink={1}>
      <Box display="flex" alignItems="center">
        <Box display="flex" flexWrap="wrap">
          <Box
            sx={{
              display: 'inline-block',
              fontSize: 24,
              fontWeight: 700,
              color: '#021660'
            }}>
            {title}
            {accounts?.length > 0 && (
              <STooltip
                arrow
                placement="right"
                title={
                  <div
                    style={{
                      whiteSpace: 'pre-line'
                    }}>
                    {accounts.map(a => a.accountName).join('\n')}
                  </div>
                }>
                <a
                  style={{
                    marginLeft: '10px',
                    fontSize: 24,
                    color: '#1E96FC',
                    cursor: 'pointer',
                    fontWeight: 400
                  }}>
                  {`+${accounts.length} More`}
                </a>
              </STooltip>
            )}
          </Box>
        </Box>
        {displayChangeClinic && (
          <Box ml={2}>
            <a href="#" onClick={onChangeClinic}>
              (Change clinic)
            </a>
          </Box>
        )}
      </Box>
      <SText noLineClamp>{subtitle}</SText>
    </Box>
    <Box flexShrink={0} ml="auto">
      {showContractDropdown && (
        <ContractDropdown
          onSelectContract={onSelectContract}
          selectedContract={selectedContract}
        />
      )}
    </Box>
    <Divider />
  </Box>
);
